import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useContext,
} from "react";
import { useFormikContext } from "formik";
import { useFormikWizard } from "formik-wizard";
import CommonOtpInput from "../CommonOtpInput/CommonOtpInput";
import { Append_0 } from "../../constants/Append_0";
import NewSpinner from "../../components/NewSpinner/NewSpinner";
import "./GlobalOtpComp.css";
import useWindowResize from "../../../hooks/useWindowResize";
import {
  bpSendOtp,
  login,
  getAllDeviceType,
  otpLogin,
  ForgotPasswordv1,
} from "../../constants/api";
import ErrorAltIcon from "../../../images/icons/errorAltIcon.svg";
import withErrorHandlerComponent from "../../../hoc/withErrorHandler/withErrorHandlerComponent";
import { UserDeviceType } from "../../../common/constants/UserDeviceDetails";
import BottomModal from "../bottomLoginModal/BottomModal";
import { DeveloperDataContext } from "../../utils/appContext";
import { faInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Otp = ({
  itsBpOnBoarding,
  itsWalkinForm,
  itsBpSignup,
  pageType,
  errOtp,
}) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(null);
  const [canResend, setCanResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(60);
  const [timeLeftToResentOtp, setTimeLeftToResentOtp] = useState(10);
  const [errMsg, setErrMsg] = useState("");
  const { values: wizardValues, status } = useFormikWizard();
  const { setFieldValue } = useFormikContext();
  const [windowWidth] = useWindowResize();
  const url = new URL(window.location.href);
  const bpPathname = url.pathname === "/bp";
  const [deviceType, setDeviceType] = useState("");
  const [allDevices, setAllDevices] = useState([]);
  const [device, setDevice] = useState(2);
  const [deviceId, setDeviceId] = useState(localStorage.getItem("deviceId"));
  const [os, setOS] = useState("");
  const buttonref = useRef(null);
  const [buttonType, setButtonType] = useState("");
  const Context = useContext(DeveloperDataContext);
  const countDown = () => {
    if (timeLeft > 0) {
      setTimeLeft(timeLeft => Append_0(timeLeft - 1, 2));
    }
  };
  useEffect(() => {
    let x = setInterval(() => countDown(), 1000);
    if (timeLeft === 0) clearInterval(x);
    return () => clearInterval(x);
  }, [timeLeft]);
  useEffect(() => {
    let y;
    if (loading !== null && !loading && timeLeftToResentOtp > -1) {
      if (timeLeftToResentOtp > 0)
        y = setInterval(() => {
          if (timeLeftToResentOtp > -1)
            setTimeLeftToResentOtp(timeLeftToResentOtp =>
              Append_0(timeLeftToResentOtp - 1, 2)
            );
        }, 1000);

      if (parseInt(timeLeftToResentOtp) === 0) {
        clearInterval(y);
        setCanResend(true);
      } else setCanResend(false);
    }
    return () => clearInterval(y);
  }, [loading, timeLeftToResentOtp]);

  useEffect(() => {
    setDeviceType(UserDeviceType());

    getAllDeviceType()
      .then(res => setAllDevices(res))
      .catch(err => {});
  }, []);

  useEffect(() => {
    if (
      (deviceType == "iPhone" ||
        deviceType == "iPad" ||
        deviceType == "iPod") &&
      deviceId == 1
    ) {
      setOS("Ios");
    } else if (deviceType == "Android" && deviceId == 1) {
      setOS("Android");
    } else if (deviceType == "macOS") {
      setOS("Osx");
    } else {
      setOS("Windows");
    }
  }, [deviceType, allDevices, deviceId]);

  useEffect(() => {
    allDevices.map(each => {
      if (each?.name == os) {
        setDevice(each?.id);
        setFieldValue("device", device);
      }
    });
  }, [os, allDevices]);

  const deleteLoginOtpError = () => {
    delete Context.appData.loginOtpError;
  };
  const resendOtp = () => {
    if (wizardValues?.login) {
      bpSendOtp(wizardValues?.login?.mobile)
        .then(res => {
          setLoading(false);
          setTimeLeftToResentOtp(10);
        })
        .catch(err => {
          setLoading(false);
          setErrMsg(
            err?.response?.data?.message ||
              "something went wrong! Please try again"
          );
        });
    } else if (pageType == "forgotPassword") {
      ForgotPasswordv1({ phoneNumber: wizardValues?.username?.mobile })
        .then(res => {
          setLoading(false);
          setTimeLeftToResentOtp(10);
        })
        .catch(err => {
          setLoading(false);
          setErrMsg(
            err?.response?.data?.message ||
              "something went wrong! Please try again"
          );
        });
    } else {
      otpLogin({ phoneNumber: wizardValues?.otpPhoneNumber?.mobile })
        .then(res => {
          setLoading(false);
          setTimeLeftToResentOtp(10);
        })
        .catch(err => {
          setLoading(false);
          setErrMsg(
            err?.response?.data?.message ||
              "something went wrong! Please try again"
          );
        });
    }
  };
  return (
    <>
      {!itsWalkinForm ? (
        <BottomModal
          isLogin
          className={` w-full flex flex-col font-roboto justify-between pt-10 md:pt-0 pb-19 md:pb-4 lg:max-w-md md:px-13 px-6 ${
            itsWalkinForm ? `` : `overflow-auto`
          }  ${windowWidth > 767 ? "" : " bg-white "}`}
          style={{
            height: windowWidth < 767 ? "" : "",
          }}
        >
          <div
            className={` flex flex-col pt-5 ${
              windowWidth < 767 ? " bg-white w-full h-full justify-between" : ""
            }  `}
          >
            {timeLeft > 0 && windowWidth < 767 ? (
              <p
                className=" relative w-full text-black-850 md:text-grey-2232 font-roboto font-light text-right"
                style={{ marginTop: -10 }}
              >
                OTP Expires in
                <span className="font-roboto text-base text-tealCol-300">
                  {` 00:${timeLeft}`}
                </span>
              </p>
            ) : (
              itsWalkinForm && (
                <button
                  className=" underline sm:ml-3 mx-auto text-center items-center content-center w-full "
                  type="submit"
                  onClick={() => setFieldValue("skipOtp", true)}
                >
                  Skip OTP
                </button>
              )
            )}
            <div className="">
              <CommonOtpInput
                length={4}
                inputStyle={{
                  ...(windowWidth < 767 && bpPathname
                    ? { background: "white" }
                    : {
                        background: "#dce9f5",
                      }),
                }}
                className="otpContainer"
                inputClassName={`otpInput text-black-700 font-light md:font-normal ${
                  windowWidth < 767 && bpPathname
                    ? `border-3 border-blue-1400`
                    : ``
                }`}
                isNumberInput
                autoFocus
                onChangeOTP={otp => {
                  setOtp(otp);
                  setFieldValue(itsWalkinForm ? "otp" : "mobileOtp", otp, true);
                  setErrMsg("");
                }}
              />
            </div>
            {timeLeft > 0 && windowWidth >= 767 ? (
              <p
                className={
                  (itsWalkinForm ? " relative " : " relative") +
                  " w-full text-black-850 md:text-grey-2232 font-roboto font-light text-center mt-4 "
                }
              >
                OTP Expires in
                <span className="font-roboto text-base text-tealCol-300">
                  {` 00:${timeLeft}`}
                </span>
              </p>
            ) : (
              itsWalkinForm && (
                <button
                  className=" underline sm:ml-3 mx-auto text-center items-center content-center w-full "
                  type="submit"
                  onClick={() => setFieldValue("skipOtp", true)}
                >
                  Skip OTP
                </button>
              )
            )}
            {status?.message || Context?.appData?.loginOtpError ? (
              <div
                className=" flex space-x-2 mt-4 p-3 items-center"
                style={{
                  backgroundColor: "#FAEDE9",
                }}
              >
                <img src={ErrorAltIcon} alt="oops!" />
                <div className="text-black-400 text-sm">
                  {withErrorHandlerComponent(
                    status?.message?.length > 0
                      ? status?.message
                      : Context?.appData?.loginOtpError
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {(wizardValues?.otpPhoneNumber?.resMsg?.message?.length > 0 ||
              wizardValues?.username?.resMsg?.message?.length > 0) &&
            !Context?.appData?.loginOtpError?.length > 0 &&
            !status?.message?.length > 0 ? (
              <div
                className="flex font-roboto space-x-2 p-3 mx-8 items-center mt-12"
                style={{
                  backgroundColor: "#F0FFFF",
                }}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ width: 20, height: 20 }}
                />
                <div className="text-black-400 text-sm">
                  {wizardValues?.otpPhoneNumber?.resMsg
                    ? wizardValues?.otpPhoneNumber?.resMsg?.message
                    : wizardValues?.username?.resMsg?.message}
                </div>
              </div>
            ) : (
              ""
            )}
            <div>
              <div className={""}>
                <button
                  className="submitButton newButton w-full h-13"
                  type="submit"
                  disabled={otp.length != 4 || Context?.appData?.waitForRequest}
                  ref={buttonref}
                  onClick={() => {
                    deleteLoginOtpError();
                  }}
                >
                  Verify
                </button>
                <button
                  className="submitButton newSignUp w-full h-13"
                  type="submit"
                  onClick={() => {
                    if (canResend) {
                      setLoading(true);
                      resendOtp();
                      setTimeLeft(60);
                      setTimeLeftToResentOtp(10);
                      setButtonType("resend");
                      setErrMsg("");
                      deleteLoginOtpError();
                    }
                  }}
                >
                  <p>Resend OTP</p>
                  <span className="absolute top-0 inset-x-3/10">
                    {loading && <NewSpinner />}
                  </span>
                </button>
              </div>
              <div className="text-center py-4 text-black-400">
                Back to Login?
                <span
                  className="text-blue-500 cursor-pointer ml-1"
                  onClick={() =>
                    url.pathname.includes("bp")
                      ? navigate("/")
                      : location.reload()
                  }
                >
                  Click Here
                </span>
              </div>
            </div>
          </div>
        </BottomModal>
      ) : (
        <div
          className={` flex flex-col pt-5 ${
            windowWidth < 767 ? " bg-white w-full h-full justify-between" : ""
          }  `}
        >
          {timeLeft > 0 && windowWidth < 767 ? (
            <p
              className={
                (itsWalkinForm ? " relative " : " absolute") +
                " w-full text-black-850 md:text-grey-2232 font-roboto font-light text-center"
              }
              style={{ marginTop: -10 }}
            >
              OTP Expires in
              <span className="font-roboto text-base text-tealCol-300">
                {` 00:${timeLeft}`}
              </span>
            </p>
          ) : (
            itsWalkinForm && (
              <button
                className=" underline sm:ml-3 mx-auto text-center items-center content-center w-full "
                type="submit"
                onClick={() => setFieldValue("skipOtp", true)}
              >
                Skip OTP
              </button>
            )
          )}
          <div className="text-center mr-3 justify-center flex">
            <CommonOtpInput
              length={4}
              inputStyle={{
                ...(windowWidth < 767 && bpPathname
                  ? { background: "white" }
                  : {
                      background: "#dce9f5",
                    }),
              }}
              className="otpContainer1"
              inputClassName={`otpInput text-black-700 font-light md:font-normal ${
                windowWidth < 767 && bpPathname
                  ? `border-3 border-blue-1400`
                  : ``
              }`}
              isNumberInput
              autoFocus
              onChangeOTP={otp => {
                setOtp(otp);
                setFieldValue(itsWalkinForm ? "otp" : "mobileOtp", otp, true);
                setErrMsg("");
              }}
            />
          </div>
          {timeLeft > 0 && windowWidth >= 767 ? (
            <p
              className={
                (itsWalkinForm ? " relative " : " absolute") +
                " w-full text-black-850 md:text-grey-2232 font-roboto font-light text-center mt-28 "
              }
            >
              OTP Expires in
              <span className="font-roboto text-base text-tealCol-300">
                {` 00:${timeLeft}`}
              </span>
            </p>
          ) : (
            itsWalkinForm && (
              <button
                className=" underline sm:ml-3 mx-auto text-center items-center content-center w-full "
                type="submit"
                onClick={() => setFieldValue("skipOtp", true)}
              >
                Skip OTP
              </button>
            )
          )}
          {status?.message || Context?.appData?.loginOtpError ? (
            <div
              className=" flex space-x-2 p-3 mx-8 items-center mt-12"
              style={{
                backgroundColor: "#FAEDE9",
              }}
            >
              <img src={ErrorAltIcon} alt="oops!" />
              <div className="text-black-400 text-sm">
                {withErrorHandlerComponent(
                  status?.message?.length > 0
                    ? status?.message
                    : Context?.appData?.loginOtpError
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {(wizardValues?.otpPhoneNumber?.resMsg?.message?.length > 0 ||
            wizardValues?.username?.resMsg?.message?.length > 0) &&
          !Context?.appData?.loginOtpError?.length > 0 &&
          !status?.message?.length > 0 ? (
            <div
              className="flex font-roboto space-x-2 p-3 mx-8 items-center mt-12"
              style={{
                backgroundColor: "#F0FFFF",
              }}
            >
              <FontAwesomeIcon
                icon={faInfo}
                style={{ width: 20, height: 20 }}
              />
              <div className="text-black-400 text-sm">
                {wizardValues?.otpPhoneNumber?.resMsg
                  ? wizardValues?.otpPhoneNumber?.resMsg?.message
                  : wizardValues?.username?.resMsg?.message}
              </div>
            </div>
          ) : (
            ""
          )}
          <div>
            <div
              className={
                (loading || (!canResend && buttonType == "resend")
                  ? "text-grey-300 cursor-not-allowed "
                  : " text-tealCol-1200 ") +
                " relative w-full text-base text-center font-roboto font-thin underline mx-auto mt-10 cursor-pointer "
              }
              style={{
                width: "fit-content",
                marginBottom: -25,
              }}
              onClick={() => {
                if (canResend) {
                  setLoading(true);
                  resendOtp();
                  setTimeLeft(60);
                  setTimeLeftToResentOtp(10);
                  setButtonType("resend");
                  setErrMsg("");
                  deleteLoginOtpError();
                }
              }}
            >
              <p>Resend OTP</p>
              <span className="absolute top-0 inset-x-3/10">
                {loading && <NewSpinner />}
              </span>
            </div>

            <div className={""}>
              <button
                type="submit"
                disabled={otp.length != 4 || Context?.appData?.waitForRequest}
                className={
                  (itsBpOnBoarding ? "w-full" : "w-10/12") +
                  " submitButton mb-4 h-13"
                }
                ref={buttonref}
                onClick={() => {
                  deleteLoginOtpError();
                }}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Otp;
