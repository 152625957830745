import React, { useEffect, useState } from "react";
import ErrorAltIcon from "../../images/icons/errorAltIcon.svg";

export default function withErrorHandlerComponent(error, status) {
  if (error && status === 403) {
    return error || "You are not authorized to view this document";
  } else {
    return error || "Oops! Something went wrong !";
  }
}
